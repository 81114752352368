.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
}

.react-flow__node {
  width: 80px;
  height: 30px;
}
/* Custom styling for the node itself to be more like Zapier */
.node-container {
    height: 100%;
    width: 100%;
    border: 2px solid #333;
    border-radius: 4px;
    position: relative;
}

.node-container.delete-marked .label-container::before, .node-container.delete-marked .label-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: red;
}

.node-container.delete-marked .label-container::before {
    transform: rotate(45deg);
}

.node-container.delete-marked .label-container::after {
    transform: rotate(-45deg);
}


.node-container .trash {
    display: none;
}

.node-container:hover .trash {
    position: absolute;
    top: -1px;
    left: 0px;
    font-size: 8px;
    cursor: pointer;
    z-index: 99;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.node-container:hover .trash:hover {
    border: 1px solid red;
}


.node-container .label-container {
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    font-size: 14px;
    background: white;
    color: #333;
}


@keyframes spinBackground {
    0% {
        background: conic-gradient(transparent 0%, transparent 25%, #000 25%, #000 100%);
    }
    100% {
        background: conic-gradient(#000 0%, #000 100%);
    }
}

@keyframes clockFill {
    0%, 10% {
        background: conic-gradient(transparent 0%, transparent 100%);
    }
    10% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 10%, transparent 10%, transparent 100%);
    }
    20% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 20%, transparent 20%, transparent 100%);
    }
    30% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 30%, transparent 30%, transparent 100%);
    }
    40% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 40%, transparent 40%, transparent 100%);
    }
    50% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 50%, transparent 50%, transparent 100%);
    }
    60% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 60%, transparent 60%, transparent 100%);
    }
    70% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 70%, transparent 70%, transparent 100%);
    }
    80% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 80%, transparent 80%, transparent 100%);
    }
    90% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 90%, transparent 90%, transparent 100%);
    }
    100% {
        background: conic-gradient(rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.5) 100%);
    }
}


.node-container .handle {
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: transparent;
    border: 2px solid #333;
    position: absolute;
    border-radius: 13px;
}

.node-container .handle:hover {
    cursor: pointer;
}


.node-container .handle::after {
    content: "+";
    color: #333; /* Color for the plus symbol */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px; /* Adjust font size as needed */
    pointer-events: none; /* Ensures the plus does not interfere with clicking */
}

.node-container .handle.right {
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.node-container .handle.right:hover,
.node-container .handle.right.loading-animation,
.node-container .handle.right.start-connection {
    right: -20px;
}

.node-container .handle.right.start-connection {
    width: initial;
    right: -139px;
    border-left: none;
    padding-right: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

}
.node-container .handle.right.start-connection:after{
    content: ""
}

.node-container .handle.right.start-connection select {
    border: none;
}


.node-container.no-type .handle.right {
    pointer-events: none;
    visibility: hidden;
}

.node-container .handle.right.loading-animation {
    animation: clockFill 1.2s linear forwards;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.5);
    pointer-events: none;
}

.node-container select.types {
    font-size: 8px;
}

.node-container select.responses {

}


.node-container .types-save-cancel-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    height: 14px;
}

.node-container .types-save-cancel-buttons span {
    cursor: pointer;
}

.node-container .types-save-cancel-buttons span.save {
    font-size: 8px;
}

.node-container .types-save-cancel-buttons span.save.disabled {
    color: #cccccc57;
    cursor: not-allowed;
    pointer-events: painted;
}

.node-container .types-save-cancel-buttons span.cancel {
    font-size: 10px;
}
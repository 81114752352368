.responseSelector {
    font-size: 9px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;

    background-color: #95ff004a;
    border-radius: 12px;
    text-align: center;
    max-width: 63px;
    padding: 3px;

    cursor: pointer;

    font-weight: 700;

    z-index: 55
}

.responseSelector.disabled {
    pointer-events: none;
}